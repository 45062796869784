<template>
  <div class="contactContainer">
    <h2>Kontakt</h2>
    <p>Wszelkie pytania kierować przez:</p>
      <ul>
        <li>Discord: Izabela1337#2925</li>
        <li>Facebook: <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/pnakody">Polowanie na kody</a></li>
      </ul>
  </div>
</template>

<style lang="scss" scoped>
.contactContainer{
  text-align: left;
  padding: 1rem;
}
</style>